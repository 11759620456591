html {
  background-color: #f5f5f5;
}
body {
  height: fit-content;
}
@keyframes scrollAnimation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
.scroll-icons {
  animation: 30s scrollAnimation infinite linear;
}
.scroll-container:hover .scroll-icons {
  animation-play-state: paused;
}
